import React from "react";
function Perso() {
    return (
        <>
            <section className="presentation_cadeaux_perso">
                <div><h1>Cadeaux personnalisés</h1>
                    <h2> Puzzles, mugs, tirelires, cadres... Découvrez notre gamme de cadeaux personnalisables, disponibles sur commande !</h2>
                </div>
            </section>
        </>
    )
}

export default Perso;